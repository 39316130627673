import React from "react"
import Layout from "../components/Layout"
import {graphql, useStaticQuery} from "gatsby"
import PageSection from "../components/PageSection";
import styles from "../css/portfolio.module.css";
import ProjectItem from "../components/singles/ProjectItem";
import Title from "../components/Title";
import SEO from "../components/SEO"

const Portfolio = ({location}) => {
    const data = useStaticQuery(pageQuery);

    return (
        <Layout>
            <SEO title="Portfolio" pathname={location.pathname}/>
            <PageSection>
                {data.page.edges.map(({node}) => {
                    return (
                        <div>
                            <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading}/>
                            <div className={styles.projects}>
                                {data.projects.edges.sort((a, b) => (a.node.frontmatter.date < b.node.frontmatter.date) ? 1 : -1).map((project) => {
                                    return (
                                        <ProjectItem projectItem={project.node.frontmatter}/>
                                    )
                                })}
                            </div>
                            <br/>
                            <Title subtitle={"Clients I've worked with"}/>
                            <div className={styles.clients}>
                                {node.frontmatter.clients.map((client) => {
                                    return (
                                        <div className={styles.client}>
                                            <a href={client.url} target="_blank">
                                                <img src={client.logo} alt={"Client image: " + client.url}
                                                     className={styles.client}/>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </PageSection>
        </Layout>
    )
};

export const pageQuery = graphql`
    query {
        page: allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/projects/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        clients {
                            logo
                            url
                        }
                    }
                }
            }
        }
        projects: allMarkdownRemark(limit: 1000 filter: {frontmatter: {templateKey: {eq: "project-template"}}}) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        thumbnail
                        date
                    }
                    frontmatter {
                        templateKey
                    }
                }
            }
        }
    }
`;

export default Portfolio